import React from "react";
import { Link } from "gatsby";
import startCase from "lodash/startCase";
import kebabCase from "lodash/kebabCase";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { usePosts } from "gatsby-theme-blog-ml-core";
import { SEO } from "gatsby-plugin-seo";
import Layout from "../../components/Layout";

import {
  Divider,
  FeaturedImage,
  Section,
  AuthorSummary,
  BlogRoll,
  ImageCaption,
  BookCtaBanner
} from "../../components/UI";

import { H1, MdxComponentMap, H3 } from "../../components/Typography";
import MailSignUp from "../../components/UI/MailSignUp";

const PagePost = ({ data }) => {
  const { blogPost } = data;
  const {
    mdx: { frontmatter, body, excerpt }
  } = blogPost;
  const {
    title,
    date,
    featuredImage,
    tags,
    slug,
    featuredImageCaption,
    featuredImageUrl,
    keywords
  } = frontmatter;
  const excludeSlugs = [slug];
  const posts = usePosts({ excludeSlugs });
  const hasFeaturedImage = featuredImage !== null;

  const tagElements = tags.map(t => {
    return (
      <li key={t} className="mr-4">
        <Link
          className="underline text-gray-900 hover:text-red-100 transition"
          to={`/tags/${kebabCase(t)}/`}
        >
          {startCase(t)}
        </Link>
      </li>
    );
  });
  return (
    <Layout>
      <SEO
        title={title}
        description={excerpt}
        ogType="article"
        image={featuredImage.publicURL}
        pagePath={slug}
        keywords={keywords}
      />
      <BookCtaBanner />
      <div className="max-w-2xl flex flex-col mx-auto pt-10 pb-4">
        <Section>
          <h2 className="font-serif text-2xl italic text-blue4 mb-2">{date}</h2>
          <H1>{title}</H1>
          {hasFeaturedImage && (
            <div>
              <FeaturedImage className="mb-4" imageInfo={featuredImage} />
              <ImageCaption
                name={featuredImageCaption}
                url={featuredImageUrl}
              />
            </div>
          )}
        </Section>
        <MDXProvider components={MdxComponentMap}>
          <Section className="pb-8">
            <MDXRenderer>{body}</MDXRenderer>
          </Section>
        </MDXProvider>
        {tags && tags.length ? (
          <Section>
            <H3>Categories</H3>
            <ul className="flex">{tagElements}</ul>
          </Section>
        ) : null}
      </div>
      {posts.length > 0 && (
        <BlogRoll
          heading="More From Howard"
          posts={posts}
          showAllPostsLink={false}
        />
      )}
      <div className="bg-gray-100">
        <div className="max-w-6xl md:flex mx-auto px-4 py-8 items-stretch">
          <div className="md:w-1/2 md:border-r md:pr-4">
            <AuthorSummary isFooter />
          </div>
          <Divider className="md:hidden" />
          <div className="md:w-1/2 md:pl-4">
            <MailSignUp />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PagePost;
